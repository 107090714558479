.container1 {
    color: #014A75;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 110px;
    width: 75vw;
}

#headline {
    font-weight: normal;
    letter-spacing: .125rem;
    text-transform: uppercase;
    font-size: 24px;
    margin-top: 40px;
}

.timerlist {
    display: inline-block;
    font-size: 1.5em;
    list-style-type: none;
    padding: 1em;
    text-transform: uppercase;
}

.timerlist span {
    display: block;
    font-size: 4.5rem;
}



@media all and (max-width: 768px) {
    h1 {
        font-size: calc(1.5rem * var(--smaller));
    }

    .timerlist {
        font-size: calc(1.125rem * var(--smaller));
    }

    .timerlist span {
        font-size: calc(3.375rem * var(--smaller));
    }
}